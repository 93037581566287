import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import Riders from "../components/Riders"

const RidersPage = (): JSX.Element => (
  <Layout>
    <SEO
      title="Become a Rider"
      description="Become a Genie rider! We're fun to work for and pay our riders properly. Make an application today and you'll hear from us within 24 hours."
    />
    <BasicPage>
      <Riders />
    </BasicPage>
  </Layout>
)

export default RidersPage
