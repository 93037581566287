import * as React from "react"
import * as styles from "./riders.module.scss"

const points = [
  {
    title: "Why ride with Genie?",
    points: [
      "Opportunity to work off-peak hours (6pm-2am)",
      "Earn a per order commission + 100% of your tips",
      "Choose your own schedule and deliver when you want",
      "Deliver from the Genie warehouse, no restaurants!",
    ],
  },
  {
    title: "What you’ll need to get started",
    points: ["18+ years old", "Smartphone", "That’s it!"],
  },
]

const Riders = (): JSX.Element => (
  <div className={styles.pageContainer}>
    <h1 className={styles.title}>Become a Rider</h1>
    {points.map((section) => (
      <div key={section.title} className={styles.section}>
        <h4 className={styles.sectionTitle}>{section.title}</h4>
        <ul className={styles.list}>
          {section.points.map((point) => (
            <li key={point} className={styles.point}>
              {point}
            </li>
          ))}
        </ul>
      </div>
    ))}
    <h4 className={styles.sectionTitle}>
      We make it quick and easy to start. Apply now! Just fill in the form below
    </h4>
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLSfZ276z-hTpYpeHPLafU5upPZ1oUfY_2-JOHm8QenOKFZ6EEw/viewform?usp=sf_link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button className={styles.btn}>APPLY</button>
    </a>
  </div>
)

export default Riders
